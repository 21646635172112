import { useHistory } from 'react-router';
import { Box } from 'reflexbox';
import { useAuth } from './AuthContext';
import { Button } from './common';
import { Emoji, EmojiType } from './emojis';
import { useGame } from './GameContext';

export default () => {
  const history = useHistory();
  const { game } = useGame();
  const { loggedInUser } = useAuth().getAuthContext();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height="100vh"
      flexDirection="column"
      p={4}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexGrow={1}
        style={{ fontSize: 40 }}
      >
        <div
          style={{
            textShadow: `0 0 17px #96EF48, 0 0 70px rgba(150,239,72,0.5)`,
            fontWeight: 'bold',
          }}
        >
          Mahjongify
        </div>
        <Emoji type={EmojiType.MAHJONG} />
        <Emoji type={EmojiType.SNEK} />
      </Box>

      <Button
        mb={2}
        flexGrow={0}
        fullWidth
        onClick={() => {
          history.push(`/userStats/${loggedInUser.id}`);
        }}
      >
        My stats
      </Button>
      <Button
        mb={2}
        flexGrow={0}
        fullWidth
        onClick={() => {
          history.push(`/groupStats`);
        }}
      >
        Group stats
      </Button>
      <Button
        flexGrow={0}
        fullWidth
        onClick={() => {
          history.push('/start');
        }}
      >
        {!game ? 'New game' : 'Continue game'}
      </Button>
    </Box>
  );
};
