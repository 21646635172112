import devil from '../assets/emojis/smiling_devil.png';
import money from '../assets/emojis/money_wings.png';
import party from '../assets/emojis/party_popper.png';
import cool from '../assets/emojis/smiling_face_sunglasses.png';
import diamond from '../assets/emojis/diamond.png';
import hands from '../assets/emojis/palms_hands.png';
import angbao from '../assets/emojis/angbao.png';
import moneyFace from '../assets/emojis/money_face.png';
import tiger from '../assets/emojis/tiger.png';
import mahjong from '../assets/emojis/mahjong.png';
import arrowUpRight from '../assets/emojis/up_right_arrow.png';
import arrowDownRight from '../assets/emojis/down_right_arrow.png';
import crown from '../assets/emojis/crown.png';
import sparkles from '../assets/emojis/sparkles.png';
import pleadingFace from '../assets/emojis/pleading_face.png';
import abacus from '../assets/emojis/abacus.png';
import hourglass from '../assets/emojis/hourglass.png';
import checkMark from '../assets/emojis/check_mark.png';
import rabbit from '../assets/emojis/rabbit.png';
import snek from '../assets/emojis/snek.png';
export enum EmojiType {
  MONEY,
  DEVIL,
  PARTY,
  COOL,
  DIAMOND,
  HANDS,
  ANGBAO,
  MONEY_FACE,
  TIGER,
  MAHJONG,
  ARROW_UP_RIGHT,
  ARROW_DOWN_RIGHT,
  CROWN,
  SPARKLES,
  PLEADING_FACE,
  ABACUS,
  HOURGLASS,
  CHECK_MARK,
  RABBIT,
  SNEK,
}

const getEmoji = (type: EmojiType) => {
  switch (type) {
    case EmojiType.MONEY:
      return money;
    case EmojiType.DEVIL:
      return devil;
    case EmojiType.PARTY:
      return party;
    case EmojiType.COOL:
      return cool;
    case EmojiType.DIAMOND:
      return diamond;
    case EmojiType.HANDS:
      return hands;
    case EmojiType.ANGBAO:
      return angbao;
    case EmojiType.MONEY_FACE:
      return moneyFace;
    case EmojiType.TIGER:
      return tiger;
    case EmojiType.MAHJONG:
      return mahjong;
    case EmojiType.ARROW_UP_RIGHT:
      return arrowUpRight;
    case EmojiType.ARROW_DOWN_RIGHT:
      return arrowDownRight;
    case EmojiType.SPARKLES:
      return sparkles;
    case EmojiType.CROWN:
      return crown;
    case EmojiType.PLEADING_FACE:
      return pleadingFace;
    case EmojiType.ABACUS:
      return abacus;
    case EmojiType.HOURGLASS:
      return hourglass;
    case EmojiType.CHECK_MARK:
      return checkMark;
    case EmojiType.RABBIT:
      return rabbit;
    case EmojiType.SNEK:
      return snek;
    default:
      throw new Error(`No corresponding emoji for type: ${type}`);
  }
};

export const Emoji = ({
  type,
  size,
  styles,
}: {
  type: EmojiType;
  size?: number;
  styles?: Record<any, any>;
}) => {
  return (
    <img
      style={{ ...styles, width: size || 40, height: size || 40 }}
      src={getEmoji(type)}
      alt={type.toString()}
    />
  );
};
